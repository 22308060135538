
import { instance } from '@/utils/http.js'

// 获取一级能力数据
export const getCapability = function (params) {
  return instance({
    url: '/api/v1/public/get_capability1',
    method: 'get',
    params
  })
}

// 能力训练
export const capabilityTrain = function (data) {
  return instance({
    url: '/api/v1/xmode/capability_train',
    method: 'post',
    data
  })
}

