<template>
  <div>
    <!-- ‘1’=>’单选’,2=>多选，3=>不定项选，4=>双项选。
	  5=>填空，6=>多子题，7=>非选择题，8=>判断正，
	  9=>ABCDEFG -->
    <div v-if="type == '1' || type == '2' ||  type == '9' ||  type == '4' || type == '3'">
      <!-- <div style="padding:20rem;font-weight: bold;">请选择</div> -->
      <!-- abcdefg的时候才会显示这个 -->
      <div v-if="type == '9'"
           class="warp">
        <div :class="{'item':true,'pitchOn':answer.indexOf(item) != -1}"
             @click="add(item)"
             v-for="item in moreSelect"
             :key="item">
          <span>
            {{item}}
          </span>
        </div>
      </div>
      <!-- 单选多选都显示这个，只有四个选项 -->
      <div v-else-if="type == '1' || type == '2' || type == '4' || type == '3'"
           class="warp">
        <div :class="{'item':true,'pitchOn':answer.indexOf(item) != -1}"
             @click="add(item)"
             v-for="item in items"
             :key="item">
          <span>
            {{item}}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      items: ['A', 'B', 'C', 'D'],
      moreSelect: ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
    }
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    answer: {
      type: Array,
      default: () => {
        return []
      }
    },
    index: {
      type: Number,
      default: 0
    },
    questionId: {
      type: Number,
      default: 0
    }
  },
  watch: {
    answer: {
      handler () {
        // console.log('answer', this.answer);
      },
      deep: true
    },
  },
  methods: {
    add (item) {
      this.$emit('setAnwer', this.questionId, item)
    },
  }
}
</script>

<style lang="scss" scoped>
.warp {
  display: flex;
  .item {
    flex: 1;
    height: 70rem;
    margin: 0 auto;
    background-color: #f7f8fc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32rem;
    color: #666666;
    font-weight: bold;
    cursor: pointer;
  }
}

.pitchOn {
  color: #ffffff !important;
  background: #2196f3 !important;
}
</style>