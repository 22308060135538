
import { instance } from '@/utils/http.js'

// 获取一级考点
export const getOneCheckpoint = function (params) {
  return instance({
    url: '/api/v1/public/check_point',
    method: 'get',
    params
  })
}

// 考点训练
export const checkPointTrain = function (data) {
  return instance({
    url: '/api/v1/bmode/weak_train_two',
    method: 'post',
    data
  })
}

// 获取二级考点
export const getTwoCheckpoint = function (params) {
  return instance({
    url: '/api/v1/public/check_point2',
    method: 'get',
    params
  })
}

// 考点讲解列表
export const getCheckpointVideoList = (params) => {
  return instance({
    url: '/api/v1/teaching/point_lst',
    method: 'get',
    params
  })
}



// 考点视频
export const checkpointVideo = (params) => {
  return instance({
    url: '/api/v1/teaching/video_list',
    method: 'get',
    params
  })
}

// 知识点视频
export const knowledgeVideo = (params) => {
  return instance({
    url: '/api/v1/public/get_knowledge_resource_video',
    method: 'get',
    params
  })
}