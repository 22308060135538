
import { instance } from '@/utils/http.js'

// 获取整卷列表
export const getWholePaperList = function (params) {
  return instance({
    url: '/api/v1/paper_source/source_lst',
    method: 'get',
    params
  })
}

// 整卷-做卷
export const doWholePaper = function (data) {
  return instance({
    url: '/api/v1/paper_source/do_paper',
    method: 'post',
    data
  })
}


// 保存用户图片.
export const savePaperImage = function (data) {
  return instance({
    url: '/api/v1/my/upload_paper_file',
    method: 'post',
    data
  })
}