import { instance } from '@/utils/http.js'

// 获取讲义类别
export const getHandoutCategory = function (params) {
  return instance({
    url: '/api/v1/lecture/get_category',
    method: 'get',
    params
  })
}

// 获取讲义
export const getList = function (params) {
  return instance({
    url: '/api/v1/lecture/lst_pc',
    method: 'get',
    params
  })
}

// 获取讲义详情
export const getInfo = function (params) {
  return instance({
    url: '/api/v1/lecture/get_lecture',
    method: 'get',
    params
  })
}

// 讲义做卷
export const lectureDoPaper = function (data) {
  return instance({
    url: '/api/v1/lecture/show_questions',
    method: 'post',
    data
  })
}