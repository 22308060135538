<template>
  <div>
    <el-dialog title="答题说明"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               :append-to-body="true"
               width="930rem"
               margin-top="282rem"
               :before-close="handleClose">
      <div class="text_warp">
        <p>1.选择题请点击选项方框。</p>
        <p>2.多选题、填空题和解答题请在自备纸张上填写题号和相应答案，完成试卷后进入答题卡页面，拍摄答案照片(最多只能上传6张照片，每张照片不超过10M)上传后点击提交答案。</p>
        <p>3.点击提交答案可以提前交卷，时间到后将自动提交选择题答案。</p>
      </div>
      <div class="btn_warp">
        <div @click="back">
          取消
        </div>
        <div @click="handleClose">
          开始答题
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,

    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
      this.$parent.showPaper()
    },
    back () {
      this.$router.go(-1)
    },
  }
}
</script>

<style lang="scss" scoped>
.text_warp {
  word-break: break-all;
  font-weight: 500;
  color: #666666 !important;
  line-height: 33rem;
  font-size: 24rem;
  padding: 40rem;
  padding-top: 0;
}
.btn_warp {
  width: 100%;
  height: 70rem;
  border-top: 1rem solid #e5e5e5;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24rem;
    &:nth-child(1) {
      font-weight: bold;
      color: #999999;
      border-right: 1rem solid #e5e5e5;
    }
    &:nth-child(2) {
      font-weight: bold;
      color: #2196f3;
    }
  }
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  display: none;
}
::v-deep .el-dialog__header {
  font-size: 18rem;
  font-weight: 500;
  color: #333333;
  text-align: center;
}
::v-deep .el-dialog__title {
  color: #333333;
  font-weight: 500;
  font-size: 24rem;
  line-height: 33rem;
}
::v-deep .el-dialog {
  border-radius: 10rem;
  position: absolute;
  margin-left: 50%;
  height: 339rem;
  transform: translateX(-50%);
  margin-top: 282rem !important;
}
</style>