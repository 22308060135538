import { instance } from '@/utils/http.js'

// 获取试卷科目模板
export const getMyPaper = function (params) {
  return instance({
    url: '/api/v1/my/my_pc_paper',
    method: 'get',
    params
  })
}

// 首页未完成试卷
export const getNotOverPaper = function (params) {
  return instance({
    url: '/api/v1/my/my_paper',
    method: 'get',
    params
  })
}

// 获取做卷详情  自评用
export const getAnalysis = function (params) {
  return instance({
    url: '/api/v1/my/analysis',
    method: 'get',
    params
  })
}

// 自评接口
export const submitUserPaper = function (data) {
  return instance({
    url: '/api/v1/my/correct_self',
    method: 'post',
    data
  })
}

// 自评接口
export const continueDoPaper = function (data) {
  return instance({
    url: '/api/v1/my/continue_paper',
    method: 'post',
    data
  })
}


// 移动端自评试卷+上传答案
export const phonePaper = function (data) {
  return instance({
    url: '/api/v1/my/submit_answer',
    method: 'post',
    data
  })
}

export const getNnotPress = (params) => {
  return instance({
    url: '/api/v1/my/my_paper',
    method: 'get',
    params
  })
}

// 根据user_paper_id删除未完成试卷
export const delPaper = (data) => {
  return instance({
    url: '/api/v1/my/del_paper',
    method: 'post',
    data
  })
}


// 根据subject_id获取我删除的试卷
export const myDelPaper = (params) => {
  return instance({
    url: '/api/v1/my/del_paper_list',
    method: 'get',
    params
  })
}
// 根据user_paper_id恢复删除的试卷
export const recoverDelPaper = (data) => {
  return instance({
    url: '/api/v1/my/undo_del_paper',
    method: 'post',
    data
  })
}