<template>
  <div v-if="dialogVisible">
    <el-dialog title="纠错"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               :append-to-body="true"
               width="750rem"
               :before-close="handleClose">
      <el-form :model="form"
               ref="formRef"
               :rules="rules"
               label-width="80rem">
        <el-row>
          <el-form-item label="ID:"
                        prop="question_id">
            <el-input placeholder="请输入纠错ID"
                      style="width:50%"
                      type="number"
                      v-model="form.question_id" />
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="纠错:"
                        prop="comment">
            <el-input placeholder="请输入纠错内容"
                      type="textarea"
                      :rows="7"
                      style="width:90%"
                      v-model="form.comment" />
          </el-form-item>
        </el-row>
      </el-form>
      <div class="btn_warp">
        <div @click="handleClose">
          退出
        </div>
        <div @click="save">
          发表
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { errorCorrect } from '@/api/error.js'

export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
      rules: {
        question_id: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        comment: [
          { required: true, message: '请输入', trigger: 'blur' },
        ]
      }
    }
  },
  methods: {
    handleClose () {
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    },
    save () {
      this.$refs.formRef.validate(async val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        form.question_id += ''
        // form.question_id = form.question_id.slice(4, form.question_id.length)
        await errorCorrect(form)
        this.handleClose()
        this.$message.success('反馈成功')
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.btn_warp {
  width: 100%;
  height: 70rem;
  border-top: 1rem solid #e5e5e5;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20rem;
    &:nth-child(1) {
      font-weight: bold;
      color: #999999;
      border-right: 1rem solid #e5e5e5;
    }
    &:nth-child(2) {
      font-weight: bold;
      color: #2196f3;
    }
  }
}
::v-deep .el-dialog__body {
  padding: 5rem 20rem;
}

::v-deep .el-dialog__header {
  font-size: 18rem;
  font-weight: 500;
  color: #333333;
  text-align: center;
}
::v-deep .el-dialog {
  border-radius: 20rem;
  height: 500rem;
  margin-top: 198rem !important;
}

::v-deep .el-form-item__label {
  color: #333333;
  font-size: 18rem;
  font-weight: 500;
}

.block {
  display: flex;
  margin-bottom: 20rem;
  span {
    padding: 7rem 18rem;
    font-size: 16rem;
    font-weight: bold;
    color: #666666;
    border-radius: 5rem;
    background: #f6f6f6;
    &:nth-child(2) {
      margin-left: 20rem;
    }
  }
}
.isActive {
  background: #007af0 !important;
  color: white !important;
}

::v-deep .el-dialog__close {
  display: none;
}

.desction {
  font-size: 16rem;
  font-weight: bold;
  color: #ff6147;
}
::v-deep .el-form-item__label {
  font-size: 20rem;
  font-weight: 500;
  color: #333333;
  line-height: 40rem;
}
::v-deep .el-input__inner {
  font-weight: 500;
  color: #999999;
  font-size: 16rem;
  height: 40rem;
  border-radius: 5rem;
  border: 1rem solid #cccccc;
}

::v-deep .el-textarea__inner {
  font-weight: 500;
  color: #999999;
  font-size: 16rem;
  height: 230rem;
  border-radius: 5rem;
  border: 1rem solid #cccccc;
}

::v-deep .el-dialog__title {
  color: #333333;
  font-weight: 500;
  font-size: 20rem;
  line-height: 33rem;
}
</style>