import { instance } from '@/utils/http.js'

// 获取一级考点
export const getChoicenessPaper = function (params) {
  return instance({
    url: '/api/v1/teacher/teacher_paper',
    method: 'get',
    params
  })
}

// 教师做卷

export const getTeacherList = function (data) {
  return instance({
    url: '/api/v1/teacher/do_two',
    method: 'post',
    data
  })
}
