

import { instance } from '@/utils/http.js'

// 获取章节
export const getChapter = function (params) {
  return instance({
    url: '/api/v1/public/get_chapter',
    method: 'get',
    params
  })
}

// 获取章节
export const getChapter2 = function (params) {
  return instance({
    url: 'api/v1/public/get_grade_chapter',
    method: 'get',
    params
  })
}




// 章节训练
export const chapterTrain = function (data) {
  return instance({
    url: '/api/v1/xmode/chapter_train_two',
    method: 'post',
    data
  })
}