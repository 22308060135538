<template>
  <div class="right_card_wrap">
    <ErrorForm ref="errorFormRef" />

    <div class="opertion_wrap">

      <img src="@/assets/icons/jc.png"
           @click="jiucuo"
           alt="">
      <!-- <img src="@/assets/icons/nz.png"
           alt=""> -->
      <img src="@/assets/icons/card.png"
           @click="toCard"
           alt="">
    </div>
    <div v-for="item in topic_numer"
         @click="toMaodian(item)"
         :style="{'background':isActive == item?'#3F9EFF':'#CCCCCC','margin-top':item == 1?'':'12rem'}"
         :key="item">
      {{item }}</div>
  </div>
</template>

<script>
import ErrorForm from './error.vue'
export default {
  data () {
    return {
      isActive: 1,
      isClick: false
    }
  },
  props: {
    topic_numer: {
      type: Number,
      default: 0
    }
  },
  components: {
    ErrorForm
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.listenerFunction, true)
  },
  mounted () {
    this.$nextTick(() => {
      document.addEventListener('scroll', this.listenerFunction, true);
    })
  },
  methods: {
    toMaodian (index) {
      this.isActive = index
      this.isClick = true
      let anchorElement = document.getElementsByClassName('yuan')[index - 1];
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: 'smooth' });
      }
      let that = this
      setTimeout(() => {
        that.isClick = false
      }, 1500)
    },
    listenerFunction (e) {
      if (!this.isClick) {
        this.$nextTick(() => {
          for (var index = 0; index < this.topic_numer; index++) {
            if (!document.getElementsByClassName('yuan')[index]) {
              document.removeEventListener('scroll', this.listenerFunction)
            }
            var getAttr = document.getElementsByClassName('yuan')[index].getBoundingClientRect()
            if (getAttr.top < 500 && getAttr.top > -500) {
              this.isActive = index + 1
            }
          }
        })
      }

    },
    toCard () {
      this.$parent.toCard()
    },
    jiucuo () {
      let form = {
        question_id: '',
        category: 0,
        comment: '',
      }
      this.$refs.errorFormRef.form = form
      this.$refs.errorFormRef.dialogVisible = true
    },
  }
}
</script>

<style lang="scss" scoped>
.right_card_wrap {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  width: 80rem;
  height: calc(100vh - 120rem);
  overflow-y: scroll;
  position: fixed;
  right: 30rem;
  top: 100rem;
  margin-bottom: 20rem;
  div {
    width: 44rem;
    height: 44rem;
    border-radius: 50%;
    background: #cccccc;
    display: flex;
    justify-content: center;
    align-content: center;
    color: white;
    font-size: 24rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .opertion_wrap {
    position: fixed;
    top: 30rem;
    right: 110rem;
    background: none;
    display: flex;
    // width: 200rem;
    img {
      background: none;
      cursor: pointer;
      width: 30rem;
      height: 30rem;
      margin-left: 30rem;
    }
  }
}
</style>